<template>
  <div style="padding: 10px">
    <div style="margin: 10px 0">
      <el-input v-model="search.accountName" placeholder="账号名" @keyup.enter.native="load" style="width: 10%"
                clearable></el-input>
      <el-input v-model="search.fuXiRow" placeholder="伏羲行号" @keyup.enter.native="load" style="width: 5%"
                clearable></el-input>
      <el-select v-model="search.parentId" clearable filterable placeholder="请选择父账号" style="width: 10%">
        <el-option
                v-for="item in parentIdDict"
                :key="item.id"
                :label="item.accountName"
                :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="search.child" clearable filterable placeholder="是否子账号" style="width: 10%">
        <el-option
                v-for="item in childDict"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey">
        </el-option>
      </el-select>
      <el-select v-model="search.horse" clearable filterable placeholder="可否打马" style="width: 10%">
        <el-option
                v-for="item in childDict"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey">
        </el-option>
      </el-select>
      <el-select v-model="search.clubCode" clearable filterable placeholder="计划俱乐部" style="width: 10%">
        <el-option
                v-for="item in clubCodeDict"
                :key="item.clubCode"
                :label="item.clubName"
                :value="item.clubCode">
        </el-option>
      </el-select>
      <el-select v-model="search.realClubCode" clearable filterable placeholder="当前俱乐部" style="width: 10%">
        <el-option
                v-for="item in clubCodeDict"
                :key="item.clubCode"
                :label="item.clubName"
                :value="item.clubCode">
        </el-option>
      </el-select>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="initTenKing">十殿初始化</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="noTenKing">十殿没打</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="noEightTenKing">十殿没过8</el-button>
      <el-button type="primary" style="margin-left: 5px" title="查询计划俱乐部和实际俱乐部不符的玩家"
                 @click="abnormalClub">俱乐部异常
      </el-button>
      <el-button style="margin-left: 5px" @click="resetData">重置</el-button>
    </div>
    <!--搜索区域-->
    <div>
      <el-badge v-for="item in clubPersonList" :key="item.clubName"
                :class="{clubClass:item.realCount<item.maxCount,clubClassErr:item.realCount>item.maxCount}">
        {{ item.clubName }}:{{ item.realCount }}/{{ item.maxCount }}
      </el-badge>
    </div>
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="accountName"
              label="账号名">
      </el-table-column>
      <el-table-column
              prop="tenKing"
              label="十殿">
      </el-table-column>
      <el-table-column
              prop="clubCode"
              :formatter="clubCodeFormatter"
              label="计划俱乐部">
      </el-table-column>
      <el-table-column
              prop="realClubCode"
              :formatter="realClubCodeFormatter"
              label="实际俱乐部">
      </el-table-column>
      <el-table-column
              prop="child"
              :formatter="childFormatter"
              label="是否小号">
      </el-table-column>
      <el-table-column
              prop="parentId"
              :formatter="parentIdFormatter"
              label="父账号">
      </el-table-column>
      <el-table-column
              prop="saltWarTeam"
              label="盐场队伍">
      </el-table-column>
      <el-table-column
              prop="xianZhu"
              :formatter="xianZhuFormatter"
              label="咸主">
      </el-table-column>
      <el-table-column
              prop="fuXiWindow"
              :formatter="fuXiWindowFormatter"
              label="伏羲窗口号+行列号">
      </el-table-column>
      <el-table-column label="操作" width="80px" v-if="this.userId === 1">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
    <!--新增编辑表单-->
    <el-dialog title="新增/编辑" v-model="dialogVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="账号名">
          <el-input v-model="form.accountName" disabled></el-input>
        </el-form-item>
        <el-form-item label="计划俱乐部" style="width: 100%">
          <el-select v-model="form.clubCode" placeholder="计划俱乐部" filterable>
            <el-option
                    v-for="item in clubCodeDict"
                    :key="item.clubCode"
                    :label="item.clubName"
                    :value="item.clubCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实际俱乐部">
          <el-select v-model="form.realClubCode" placeholder="实际俱乐部" filterable>
            <el-option
                    v-for="item in clubCodeDict"
                    :key="item.clubCode"
                    :label="item.clubName"
                    :value="item.clubCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="十殿情况">
          <el-input v-model="form.tenKing" @keyup.enter.native="save"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: 'accountInfo',
  components: {},
  data() {
    return {
      dialogVisible: false,
      parentIdDict: [],
      accountInfoDict: [],
      clubPersonList: [],
      childDict: [{"dictKey": 1, "dictValue": "是"}, {"dictKey": 0, "dictValue": "否"}],
      clubCodeDict: [],
      form: {
        createTime: '',
      },
      userId: '',
      loading: true,
      search: {},
      query: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
    }
  },
  created() {
    this.load()
    let userStr = sessionStorage.getItem("user") || "{}"
    let user = JSON.parse(userStr)
    this.userId = user.id;
  },
  mounted() {
    this.getClubCodeDict();
    this.getParentIdList();
    this.getAccountInfoList();
  },
  methods: {
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    save() {
      this.loading = true;
      if (this.form.id) {  // 更新
        request.post("/accountInfo/update", this.form).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: res.msg
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() // 刷新表格的数据
          this.loading = false
          this.dialogVisible = false  // 关闭弹窗
        })
      }
    },
    exportUser() {
      location.href = "http://182.92.223.123:9090/saltedFishSource/export";
    },
    resetData() {
      this.search = {}
      this.load()
    },
    getAccountInfoList() {
      request.get("/accountInfo/listNoPage", {}).then(res => {
        this.loading = false
        this.accountInfoDict = res.data
      })
    },
    getParentIdList() {
      request.get("/accountInfo/parentList", {}).then(res => {
        this.loading = false
        this.parentIdDict = res.data
      })
    },
    getClubCodeDict() {
      request.get("/clubInfo/listNoPage", {}).then(res => {
        this.loading = false
        this.clubCodeDict = res.data
      })
    },
    initTenKing() {
      request.get("/accountInfo/initTenKing", {}).then(res => {
        if (res.code === '0') {
          this.$message({
            type: "success",
            message: res.msg
          })
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
      })
    },
    noTenKing() {
      this.search = {}
      this.search.child = 0;
      this.search.tenKing = "初始化";
      this.load();
    },
    noEightTenKing() {
      this.search = {}
      this.search.child = 0;
      this.search.noEightTenKing = 1;
      this.load();
    },
    abnormalClub() {
      this.search = {}
      this.search.abnormalClub = 1;
      this.load();
    },
    load() {
      this.loading = true
      request.get("/accountInfo/clubPerson", {}).then(res => {
        this.clubPersonList = res.data
      })
      const searchForm = {...this.search}
      let obj = Object.assign(searchForm, this.query)
      let reqData = {
        ...obj
      }
      reqData.current = this.currentPage
      reqData.size = this.pageSize
      request.get("/accountInfo/list", {
        params: reqData
      }).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(pageSize) {   // 改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {  // 改变当前页码触发
      this.currentPage = pageNum
      this.load()
    },
    clubCodeFormatter(row) {
      if (row.clubCode === null || row.clubCode === -1) {
        return "无";
      }
      const list = this.clubCodeDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].clubCode === row.clubCode) {
            return list[i].clubName
          }
        }
      }
    },
    realClubCodeFormatter(row) {
      if (row.realClubCode === null || row.realClubCode === -1) {
        return "无";
      }
      const list = this.clubCodeDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].clubCode === row.realClubCode) {
            return list[i].clubName
          }
        }
      }
    },
    childFormatter(row) {
      if (row.child === null) {
        return "无";
      }
      const list = this.childDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].dictKey === row.child) {
            return list[i].dictValue
          }
        }
      }
    },
    parentIdFormatter(row) {
      if (row.parentId === null) {
        return "无";
      }
      const list = this.parentIdDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === row.parentId) {
            return list[i].accountName
          }
        }
      }
    },
    xianZhuFormatter(row) {
      if (row.xianZhu === null) {
        return "无";
      }
      const list = this.accountInfoDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === row.xianZhu) {
            return list[i].accountName
          }
        }
      }
    },
    fuXiWindowFormatter(row) {
      if (row.fuXiWindow === null) {
        return "-"
      }
      return row.fuXiWindow + "号__[" + row.fuXiRow + "行," + row.fuXiColumn + "列]"
    }
  }
}
</script>
<style>
.clubClass {
  color: cornflowerblue;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 40px;
  margin-left: 40px;
}

.clubClassErr {
  color: red;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 40px;
  margin-left: 40px;
}
</style>
